import { createSlice } from '@reduxjs/toolkit'
import initState from '@/pages/Edit/redux/initState'

export default createSlice({
    name: 'newEdit',
    initialState: initState.newEdit,
    reducers: {
        setMode(state, { payload }) {
            state.mode = payload
        },
        setFocusId(state, { payload }) {
            state.focusId = payload
        },
        setHoverId(state, { payload }) {
            state.hoverId = payload
        },
        setEnableAnimation(state, { payload }) {
            state.enableAnimation = payload
        },
        setScale(state, { payload }) {
            state.scale = payload
        },
        setGuideVideoVisible(state, { payload }) {
            state.guideVideoVisible = payload
        },
        setGuideVideoVisible2(state, { payload }) {
            state.guideVideoVisible2 = payload
        },
        setCurrentAiHelperPageId(state, { payload }) {
            state.currentAiHelperPageId = payload
        },
        setCurrentSelectedHTML(state, { payload }) {
            state.currentSelectedHTML = payload
        },
        setCurrentReplaceHTMLMode(state, { payload }) {
            state.currentReplaceHTMLMode = payload
        },
        // 设置修改表达类型 attractive, expand, abbreviate, extract, translate
        setCurrentModifyType(state, { payload }) {
            state.currentModifyType = payload
        },
        setSeries(state, { payload }) {
            const { id, value } = payload
            state.series[id] = value
        },
        setFirstModifyHTMLValue(state, { payload }) {
            state.firstModifyHTMLValue = payload
        },
        setShowFormat(state, { payload }) {
            state.showFormat = payload
        },
        setToolbarCanScroll(state, { payload }) {
            state.toolbarCanScroll = payload
        },
        setFocusCanvas(state, { payload }) {
            state.focusCanvas = payload
        },
    },
})
