// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/zmj/Workspace/jz/test/jzpptx/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/pptx",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LayoutContainer__index' */'@/pages/LayoutContainer/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/pptx/",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPtxIndex__index' */'@/pages/PPtxIndex/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/landecom",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPtxIndex__landecom' */'@/pages/PPtxIndex/landecom'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/landeword",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPtxIndex__landeword' */'@/pages/PPtxIndex/landeword'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/frame/simple",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPtxIndex__component__Frame__SimpleTemplate' */'@/pages/PPtxIndex/component/Frame/SimpleTemplate'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/client",
        "exact": true,
        "title": "客户端下载_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPtxClientIndex__index' */'@/pages/PPtxClientIndex/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/pptPlugin",
        "exact": true,
        "title": "插件下载_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPTPluginIndex__index' */'@/pages/PPTPluginIndex/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/export",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Export__index' */'@/pages/Export/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/login",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginPage__index' */'@/pages/LoginPage/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/templates",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates__index' */'@/pages/Templates/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/templatesold",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates__index' */'@/pages/Templates/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/templates/old",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates__index' */'@/pages/Templates/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/detail",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__templateDetail__index2' */'@/pages/templateDetail/index2'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/old/detail",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__templateDetail__index2' */'@/pages/templateDetail/index2'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/icon",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateIcon__index' */'@/pages/TemplateIcon/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/image",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateImage__index' */'@/pages/TemplateImage/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/copyright",
        "exact": true,
        "title": "版权声明_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateCopyright__index' */'@/pages/TemplateCopyright/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/memberAgreement",
        "exact": true,
        "title": "会员协议_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MemberAgreement__index' */'@/pages/MemberAgreement/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/userAgreement",
        "exact": true,
        "title": "用户协议_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateUserAgreement__index' */'@/pages/TemplateUserAgreement/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/agreement/introduction",
        "exact": true,
        "title": "产品介绍_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateUserAgreement__baidu' */'@/pages/TemplateUserAgreement/baidu'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/legalNotice",
        "exact": true,
        "title": "法律声明_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateLegalNotice__index' */'@/pages/TemplateLegalNotice/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/abu",
        "exact": true,
        "title": "关于我们_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Abu__index' */'@/pages/Abu/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/aboutUs",
        "exact": true,
        "title": "关于我们_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateAboutUs__index' */'@/pages/TemplateAboutUs/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/edit",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Edit__EditNew' */'@/pages/Edit/EditNew'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/edit-old",
        "exact": true,
        "title": "编辑PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Edit__index' */'@/pages/Edit/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/edit/demonstrator",
        "exact": true,
        "title": "演示者模式_观众视图_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DemonstratorOther__index' */'@/pages/DemonstratorOther/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/sharePage",
        "exact": true,
        "title": "协作分享_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SharePage__index' */'@/pages/SharePage/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/edit/home",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPtxIndex__index' */'@/pages/PPtxIndex/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/personalCenter",
        "exact": true,
        "title": "下载记录_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PersonalCenter__index' */'@/pages/PersonalCenter/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/edit/workspace",
        "exact": true,
        "title": "工作台_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EditWorkspace__index2' */'@/pages/EditWorkspace/index2'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/pictureAudit",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PictureAudit__index' */'@/pages/PictureAudit/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/edit/dustbin",
        "exact": true,
        "title": "回收站_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EditDustbin__index' */'@/pages/EditDustbin/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/audit/template",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AuditTemplate__index' */'@/pages/AuditTemplate/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/splitNode/template",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SplitNode__index' */'@/pages/SplitNode/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/778899/test",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Test__index' */'@/pages/Test/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/preziPresent",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Prezi__index' */'@/pages/Prezi/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/refund",
        "exact": true,
        "title": "秒出PPT退款页面",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Refund__index' */'@/pages/Refund/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/generate",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Generate__index' */'@/pages/Generate/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/generate-template",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Generate__template' */'@/pages/Generate/template'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/generate-by-file",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Generate__word' */'@/pages/Generate/word'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/generatePPT",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GeneratePPT__index' */'@/pages/GeneratePPT/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/generatePPT2",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GeneratePPT2__index' */'@/pages/GeneratePPT2/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/generatePPTAuto",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GeneratePPTAuto__index' */'@/pages/GeneratePPTAuto/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/simplePPT",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SimplePPT__index' */'@/pages/SimplePPT/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/templateScore",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdjustTemplateScore__index' */'@/pages/AdjustTemplateScore/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/simplePPTOverlap",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SimplePPTOverlap__index' */'@/pages/SimplePPTOverlap/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/contactUs",
        "exact": true,
        "title": "contactUs_slideinstantly",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ContactUs__index' */'@/pages/ContactUs/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/privacyPolicy",
        "exact": true,
        "title": "privacyPolicy_slideinstantly",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PrivacyPolicy__index' */'@/pages/PrivacyPolicy/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/ReturnPolicy",
        "exact": true,
        "title": "ReturnPolicy_slideinstantly",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ReturnPolicy__index' */'@/pages/ReturnPolicy/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/pdf/down",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PDFDownPage__index' */'@/pages/PDFDownPage/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/blog",
        "exact": true,
        "title": "秒出学院_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPTBlogPage__index' */'@/pages/PPTBlogPage/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/blog/detail",
        "exact": true,
        "title": "秒出学院_文章_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PPTBlogPage__blogDetail' */'@/pages/PPTBlogPage/blogDetail'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/pageGetData",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PageGetData__index' */'@/pages/PageGetData/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/animaker",
        "exact": true,
        "title": "主题背景_各种形状",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Animaker__index' */'@/pages/Animaker/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/animaker/list",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AnimakerList__index' */'@/pages/AnimakerList/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/animaker/change",
        "exact": true,
        "title": "MG人物调整页面",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AnimakerChange__index2' */'@/pages/AnimakerChange/index2'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/newDetail",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplateNewDetail__index' */'@/pages/TemplateNewDetail/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/fc/downPPT",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fc__downPPT' */'@/pages/fc/downPPT'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/mg-preview",
        "exact": true,
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MgPreview__index' */'@/pages/MgPreview/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/rights",
        "exact": true,
        "title": "权益对比_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rights' */'@/pages/Rights'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/mg",
        "exact": true,
        "title": "一键生成AI动画_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MgIndexNew' */'@/pages/MgIndexNew'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/prezi",
        "exact": true,
        "title": "一键生成巨幕模板_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PreziIndexNew' */'@/pages/PreziIndexNew'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/template/privacy",
        "exact": true,
        "title": "隐私保护声明",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TemplatePrivacy__index' */'@/pages/TemplatePrivacy/index'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/video",
        "exact": true,
        "title": "一键生成视频模板_秒出PPT",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VideoIndexNew' */'@/pages/VideoIndexNew'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/resume-index",
        "exact": true,
        "title": "秒出简历",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Resume__PayIndex' */'@/pages/Resume/PayIndex'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/resume-edit",
        "exact": true,
        "title": "秒出简历",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Resume__PayEdit' */'@/pages/Resume/PayEdit'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/resume-interview",
        "exact": true,
        "title": "秒出简历",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Resume__PayInterview' */'@/pages/Resume/PayInterview'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/admin/hot-key-config",
        "exact": true,
        "title": "热搜关键词",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__HotKeyConfig' */'@/pages/admin/HotKeyConfig'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/admin/svg-test",
        "exact": true,
        "title": "svg测试",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__SvgTest' */'@/pages/admin/SvgTest'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/ae-video-preview",
        "exact": true,
        "title": "ae视频预览",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AeVideoPreview' */'@/pages/AeVideoPreview'), loading: LoadingComponent})
      },
      {
        "path": "/pptx/ae-video-preview-option",
        "exact": true,
        "title": "ae组合视频预览",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AeVideoPreview__optionPage' */'@/pages/AeVideoPreview/optionPage'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
