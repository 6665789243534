import { clone, cloneDeep, isObject } from 'lodash'
import { getTextContentByHTML } from '@/utils/text'
import { getDataByPage } from '../common/node/nodemark'
import { post } from '@/utils/http'
import { getJzImageUrl } from '@/utils'

const searchPicture = async (node, page) => {
    const nodes = page.nodes
    if (!page?.nodes) return
    const textNodeList = Object.keys(nodes).map((nodeId) => nodes[nodeId]).filter((node) => node.type === 'text')
    const data = getDataByPage(page)
    let title = ''
    if (data && data?.title?.value) {
        title = getTextContentByHTML(data.title.value)
    }
    const payload = {
        nodes: textNodeList,
        title,
    }
    try {
        const result = await post('/ppt/picture/searchImageByPageNodes', payload)
        if (result) {
            node.value = result
        }
    } catch (error) {
        node.value = getJzImageUrl('fmdjksfjkdsnfj.png')
    }
}

export const searchPicByPage = async (page) => {
    if (!isObject(page?.nodes) || page.type === 'html') {
        return page
    }
    const p = cloneDeep(page)
    const nodes = p.nodes
    const nodeIds = Object.keys(nodes)
    // 获取type为text的nodeList

    for (let i = 0; i < nodeIds.length; i++) {
        const nodeId = nodeIds[i]
        const node = nodes[nodeId]
        if (node.type === 'image' && node.value === 'waitSearchPic') {
            await searchPicture(node, p)
        }
    }

    return p
}
