// 错误类型

export const PDF_DOWN_ERROR = 'PDF_DOWN_ERROR'
export const GENERATE_SYLLABUS_ERROR = 'GENERATE_SYLLABUS_ERROR'
export const RENDER_ERROR = 'RENDER_ERROR'
export const TYPING_ERROR = 'TYPING_ERROR'
export const IMAGE_LOAD_ERROR = 'IMAGE_LOAD_ERROR'
export const IMAGE_LOAD_AD_BLOCK_ERROR = 'IMAGE_LOAD_AD_BLOCK_ERROR'
export const REPLACE_PAGE_ERROR = 'REPLACE_PAGE_ERROR'
export const LOAD_PAGE_ERROR = 'LOAD_PAGE_ERROR'
export const GENERATE_SYLLABUS_PPT_NOTITLE_ERROR = 'GENERATE_SYLLABUS_PPT_NOTITLE_ERROR'
export const CHARTS_RENDER_ERROR = 'CHARTS_RENDER_ERROR'
export const TRANSITION_DELETE_ERROR = 'TRANSITION_DELETE_ERROR'
export const MAIN_SUBTITLE_DELETE_ERROR = 'MAIN_SUBTITLE_DELETE_ERROR'
export const DOWN_ERROR = 'DOWN_ERROR'
export const DOWN_PDF_ERROR = 'DOWN_PDF_ERROR'
export const SYLLABUS_HELP_ERROR = 'SYLLABUS_HELP_ERROR'
export const RECOMMEND_STRUCTURE_VALUE_ERROR = 'RECOMMEND_STRUCTURE_VALUE_ERROR'
export const TEMPLATE_CHANGE_ERROR = 'TEMPLATE_CHANGE_ERROR'
export const GENERATE_PPT_API_ERROR = 'GENERATE_PPT_API_ERROR'

// word导入获取信息错误
export const WORD_IMPORT_PROCESS_INFO_ERROR = 'WORD_IMPORT_PROCESS_INFO_ERROR'
// 运行动画卡住
export const RUN_ANIMATION_ERROR = 'RUN_ANIMATION_ERROR'

export const SAVE_PAGE_ERROR = 'SAVE_PAGE_ERROR'

// WORD一键生成报错
export const WORD_ONE_GENERATE_ERROR = 'WORD_ONE_GENERATE_ERROR'

// 获取大纲接口调用
export const GET_CATALOG = 'GET_CATALOG'

// 一键生成超时
export const ONE_GENERATE_TIMEOUT = 'ONE_GENERATE_TIMEOUT'
