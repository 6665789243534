import {
    CHARTS_RENDER_ERROR, DOWN_ERROR,
    GENERATE_SYLLABUS_ERROR,
    IMAGE_LOAD_AD_BLOCK_ERROR,
    IMAGE_LOAD_ERROR, MAIN_SUBTITLE_DELETE_ERROR, PDF_DOWN_ERROR, RECOMMEND_STRUCTURE_VALUE_ERROR,
    RENDER_ERROR, SYLLABUS_HELP_ERROR, TEMPLATE_CHANGE_ERROR, TRANSITION_DELETE_ERROR,
    TYPING_ERROR,
    LOAD_PAGE_ERROR,
    WORD_IMPORT_PROCESS_INFO_ERROR,
    RUN_ANIMATION_ERROR,
    SAVE_PAGE_ERROR,
    WORD_ONE_GENERATE_ERROR,
    GET_CATALOG,
    ONE_GENERATE_TIMEOUT,
} from '@/common/log/type/errorType'
import {
    EXPORT_PPT_ANIMATION_ERROR,
    EXPORT_PPT_ERROR,
    GENERATE_NONE_CONTENT_PPT, GENERATE_PPT_HTML_CONTENT_RE, GENERATE_PPT_PAGELIST_ERROR,
    GENERATE_PPT_SYLLABUS,
    GENERATE_PPT_SYLLABUS_ERROR,
    GENERATE_PPT_SYLLABUS_TEMPLATES_MORE,
    GENERATE_PPT_SYLLABUS_TIME_MORE,
    GENERATE_PPT_SYLLABUS_TIME_MORE_120, GENERATE_PPT_SYLLABUS_TIMEOUT_ERROR,
    GENERATE_SYLLABUS_TIME_MORE,
    GENERATE_SYLLABUS_TIME_MORE_1,
    ONE_GENERATE_CHANGE_TEMPLATE,
    ONE_GENERATE_CONTENT_PPT,
    ONLINE_QUERY,
    RUNTIME_ERROR,
    UNHANDLED_PROMISE_REJECT,
    GENERATE_PPT_WORD_IMPORT_INFO_ERROR,
} from '@/common/log/type/logType'
import * as request from '@/service/services'
import { postWebhookFrontRunErrMsg } from '@/service/services'

export default (type) => {
    switch (type) {
        case PDF_DOWN_ERROR:
        case CHARTS_RENDER_ERROR:
        case GENERATE_PPT_SYLLABUS_ERROR:
        case GENERATE_PPT_PAGELIST_ERROR:
        case MAIN_SUBTITLE_DELETE_ERROR:
        case DOWN_ERROR:
        case TRANSITION_DELETE_ERROR:
        case GENERATE_SYLLABUS_ERROR:
        case GENERATE_SYLLABUS_TIME_MORE_1:
        case GENERATE_NONE_CONTENT_PPT:
        case ONE_GENERATE_CHANGE_TEMPLATE:
        case ONE_GENERATE_CONTENT_PPT:
        case ONLINE_QUERY:
        case SYLLABUS_HELP_ERROR:
        case IMAGE_LOAD_ERROR:
        case RECOMMEND_STRUCTURE_VALUE_ERROR:
        case TEMPLATE_CHANGE_ERROR:
        case GENERATE_PPT_SYLLABUS_TIME_MORE:
        case GENERATE_PPT_SYLLABUS_TIMEOUT_ERROR:
        case UNHANDLED_PROMISE_REJECT:
        case EXPORT_PPT_ERROR:
        case LOAD_PAGE_ERROR:
        case EXPORT_PPT_ANIMATION_ERROR:
        case GENERATE_PPT_HTML_CONTENT_RE:
        case RUN_ANIMATION_ERROR:
        case SAVE_PAGE_ERROR:
        case GENERATE_PPT_WORD_IMPORT_INFO_ERROR:
        case WORD_ONE_GENERATE_ERROR:
        case GET_CATALOG:
        case ONE_GENERATE_TIMEOUT:
        case WORD_IMPORT_PROCESS_INFO_ERROR: {
            // 重要报错
            return request.postWebhook
        }
        case GENERATE_PPT_SYLLABUS_TEMPLATES_MORE:
        case GENERATE_SYLLABUS_TIME_MORE:
        case GENERATE_PPT_SYLLABUS_TIME_MORE_120:
        case GENERATE_PPT_SYLLABUS:
        case IMAGE_LOAD_AD_BLOCK_ERROR:
        {
            // 不重要报错
            return request.postWebhookWarn
        }
        case RENDER_ERROR:
        case RUNTIME_ERROR:
        case TYPING_ERROR: {
            // 前端报错
            return request.postWebhookFrontRunErrMsg
        }
        default: {
            return request.postWebhookWarn
        }
    }
}
