import store from 'store'

const AOrB = (id) => {
    return !!(id % 2)
}

export const isA = (u) => {
    const user = u || store.get('user')
    return !AOrB(user?.id)
}
export const isB = (u) => {
    try {
        if (__LOC__) {
            return true
        }
        const user = u || store.get('user')

        return !!AOrB(user?.id)
    } catch (e) {
        console.log(e)
    }
}

export const isNewUserB = (u) => {
    const user = u || store.get('user')
    if (__DEV__) {
        return isB()
    }
    return isB()
}
// a 为false
// b 为 true
// a 测保留原始
// b 为新功能

// 0 2为A，1,3为B
export const isNewB = (u) => {
    const user = u || store.get('user')
    return user?.id % 4 === 2 || user?.id % 4 === 3
}

export default AOrB
