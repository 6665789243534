// 替换html字符串中的font-size

import tinycolor from 'tinycolor2'
import { exportElementAsImage, getElementPrimaryColor } from '@/utils/exportImg'

export function replaceHtmlFontSize(htmlStr, fontSize) {
    let div = document.createElement('div')
    div.innerHTML = htmlStr

    // 缩小font-size
    let updatedHTML = div.innerHTML
    let regex = /font-size:\s?([\d.]+)px/gi
    updatedHTML = updatedHTML.replace(regex, (match, p1) => {
        let newSize = fontSize
        return `font-size: ${newSize}px`
    })

    return updatedHTML
}

export function getFirstFontSize(htmlStr) {
    // 使用正则表达式来匹配fontSize
    let regex = /font-size:\s?([\d.]+)(px|em|%|pt)/i
    let match = htmlStr.match(regex)

    if (match && match.length > 1) {
        return parseFloat(match[1]) // 返回找到的第一个fontSize（只返回数字部分）
    }

    return null // 如果没有找到fontSize，返回null
}
// 获取node节点实际的fontSize大小

export const getFontSizeByNode = (node) => {
    if (!node.value || typeof node.value !== 'string') return
    const fontSize = getFirstFontSize(node.value) || node.style.fontSize
    return fontSize * (node.textScale || 1)
}

// 获取page中的titleNode
export const getPageTitleNodeByPage = (page) => {
    const pageTitle = page?.nodeMark?.pageTitle
    if (!pageTitle) {
        return undefined
    }
    if (Array.isArray(pageTitle) && pageTitle[0]) {
        return page.nodes[pageTitle[0]]
    }
    return page.nodes[pageTitle]
}

export const getPageTitleByPage = (page) => {
    const titleNode = getPageTitleNodeByPage(page)
    if (!titleNode) return ''

    let div = document.createElement('div')
    div.innerHTML = titleNode.value

    return div.textContent
}

// 传入page和node，找出node背后的节点用以识别，并且找出层级最高的那个节点
function findTopZIndexWrappingNode(nodeList, targetNode) {
    const wrappingNodes = nodeList.filter((node) => {
        return (
            node.style.left <= targetNode.style.left
          && node.style.top <= targetNode.style.top
          && node.style.left + node.style.width >= targetNode.style.left + targetNode.style.width
          && node.style.top + node.style.height >= targetNode.style.top + targetNode.style.height
        )
    })

    // 如果没有包裹住 targetNode 的节点，直接返回 null
    if (wrappingNodes.length === 0) {
        return null
    }

    return wrappingNodes.reduce((maxZNode, currentNode) => {
        const currentZIndex = currentNode.style.zIndex || 0
        const maxZNodeIndex = maxZNode.style.zIndex || 0

        return currentZIndex > maxZNodeIndex ? currentNode : maxZNode
    }, wrappingNodes[0]) // 初始值设为 wrappingNodes 的第一个元素
}

// 识别node节点后的背景色
export const getNodeBackGroundColorByPage = (page, rectObj) => {
    // const nodeList = Object.values(page.nodes)
    // const wrappingNode = findTopZIndexWrappingNode(nodeList, node)
    // const wrappingNodeId = wrappingNode.id
    const pageId = page.id
    const targetEl = document.querySelectorAll(`[id="${pageId}"]`)?.[0]
    console.log('targetEl', targetEl)
    return new Promise((resolve, reject) => {
        if (targetEl) {
            getElementPrimaryColor(targetEl, rectObj).then((color) => {
                resolve(color)
            }).catch((err) => {
                reject(err)
            })
        } else {
            reject('error')
        }
    })
}

// 获取element的主题色，这是一个异步方法

export function isLightColor(color) {
    const tc = tinycolor(color)
    // getBrightness() 返回值范围是 0-255
    return tc.getBrightness() > 128
}

/**
 * 获取字符串渲染在DOM上的实际宽度
 * @param {string} text 要测量的文本
 * @param {number} fontSize 文本的大小
 * @return {number} 返回文本的宽度
 */
export function getTextWidth(text, fontSize, otherStyle) {
    // 创建一个临时元素用于测量
    const element = document.createElement('span')
    element.textContent = text
    element.style.fontSize = `${fontSize}px`
    element.style.position = 'absolute'
    element.style.left = '-9999px'
    element.style.whiteSpace = 'nowrap' // 为了避免文本折行
    document.body.appendChild(element)

    // 获取元素的宽度
    const width = element.offsetWidth

    // 清除临时元素
    document.body.removeChild(element)

    return width
}
