import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import initState from '@/pages/Edit/redux/initState'
import { getPagesByPptxId } from '@/service/services'
import { decodePage } from '@/pages/Edit/common'
import getPageList from '@/pages/Edit/redux/common/getPageList'
import updateNodeHandler from '@/pages/Edit/redux/common/updateNodeHandler'
import deleteNodeHandler from '@/pages/Edit/redux/common/deleteNodeHandler'
import createNodeWithPushHandler from '@/pages/Edit/redux/common/createNodeWithPushHandler'
import { processErrorDataPage } from '../../utils'
import { generateHTMLPage } from '../../component/NewEdit/utils/generateHTMLPageByPPTPage'
import { processTextUUID } from '../../utils/processTextUUID'
import isPreziTemplate from '@/common/isPreziTemplate'
import getPreziPageList from '@/pages/Prezi/getPreziPageList'
import { CustomLog } from '@/common/log'
import { LOAD_PAGE_ERROR } from '@/common/log/type/errorType'
import processPageZIndex from '@/utils/page/processPageZIndex'
import { searchPicByPage } from '../../utils/searchPic'
import { adjustZoomTextFontSizeByPage } from '@/utils/text/adjustZoomTextFontSize'

export const fetchPage = async (payload, store) => {
    let pptId
    let showNewEditor
    if (typeof payload === 'string' || typeof payload === 'number') {
        pptId = payload
    } else {
        pptId = payload.pptId
        showNewEditor = payload.showNewEditor
    }
    let theme
    if (store && store.getState) {
        const { ppt, themes } = store.getState()
        theme = themes?.[ppt?.themeId]
    }
    try {
        const data = await getPagesByPptxId({ pptId, limit: 199, page: 1 })
        let pageList = data.dataList.map((item) => decodePage(item))

        if (theme && isPreziTemplate(theme)) {
            pageList = getPreziPageList({ pageList, theme })
        }
        const obj = {}
        let sort = 1
        let chapterNum = 0
        let mainNum = 0
        pageList.forEach((p) => {
            const page = cloneDeep(p)
            if (!page.id) {
                guaLog('page数据错误', page)
                new CustomLog({ message: 'page数据错误', type: LOAD_PAGE_ERROR, info: page }).push()
                return
            }
            processTextUUID(page)
            processPageZIndex(page)
            // 特殊处理目录页，因为目录页有可能是后端给的，需要调整缩排
            if (page.type === 'catalog') {
                adjustZoomTextFontSizeByPage(page)
            }
            const newPage = processErrorDataPage(page)
            if (showNewEditor) {
                // 如果模板类型是 preziTemplate 并且页面类型是 cover 或 transition，则跳过创建
                if (theme && isPreziTemplate(theme) && (page.type === 'cover' || page.type === 'transition')) {
                    // 这里什么都不做，直接跳过
                } else {
                    // 如果不是 preziTemplate 或者页面类型不是 cover/transition，继续执行
                    if (page.type === 'transition') {
                        chapterNum++
                        mainNum = 0
                    }
                    if (page.type === 'main') {
                        mainNum++
                    }
                    const htmlPage = generateHTMLPage(page, {
                        chapterNum,
                        mainNum,
                    })
                    obj[htmlPage.id] = htmlPage
                    obj[htmlPage.id].sort = sort++
                }
            }
            obj[page.id] = newPage
            if (showNewEditor) {
                obj[page.id].sort = sort++
            }
        })
        return obj
    } catch (e) {
        guaLog('error', e)
        if (e.code) {
            throw new Error(e.code)
        } else {
            throw new Error('0')
        }
    }
}

export const fetchPagesThunk = createAsyncThunk(
    'pages/fetchPagesThunk',
    fetchPage,
)

export default createSlice({
    name: 'pages',
    initialState: initState.pages,
    reducers: {
        set(state, { payload }) {
            return payload
        },
        updatePage(state, { payload }) {
            const { page } = payload
            if (page instanceof Array) {
                page.forEach((item) => {
                    state[item.id] = item
                })
            } else {
                state[page.id] = page
            }
        },
        deletePage(state, { payload }) {
            const { id } = payload
            if (id instanceof Array) {
                id.forEach((key) => {
                    delete state[key]
                })
            } else {
                delete state[id]
            }
        },
        deleteNode(state, { payload }) {
            const { pageId, nodeId } = payload
            const page = state[pageId]
            page.isWarning = true
            deleteNodeHandler({ nodeId, page })
        },
        deleteNodeById(state, { payload }) {
            const { pageId, id } = payload
            const page = state[pageId]
            page.isWarning = true
            deleteNodeHandler({ nodeId: id, page })
        },
        updateNode(state, { payload, ...arr }) {
            let { pageId, node } = payload
            const page = state[pageId]
            updateNodeHandler(page, node)
        },
        updateNodes(state, { payload, ...arr }) {
            const { pageId, nodes } = payload
            const page = state[pageId]
            updateNodeHandler(page, nodes)
        },
        createNodeWithPush(state, { payload }) {
            const { pageId, node } = payload
            const page = state[pageId]
            createNodeWithPushHandler({ page, node })
        },
        createPage(state, { payload }) {
            const { page, siblingId } = payload
            state[page.id] = page
            const pageList = getPageList(state)
            const siblingIndex = pageList.findIndex((item) => item.id === siblingId)
            if (siblingIndex === 0) {
                let sort = 0
                page.sort = sort
                pageList
                    .filter((item) => item.id !== page.id)
                    .forEach((item, index) => {
                        sort += 1
                        item.sort = sort
                    })
            } else if (siblingId === null || siblingId === undefined) {
                page.sort = pageList.length - 1
                pageList
                    .filter((item) => item.id !== page.id)
                    .forEach((item, index) => {
                        item.sort = index
                    })
            } else {
                let sort = -1
                pageList
                    .filter((item) => item.id !== page.id)
                    .forEach((item, index) => {
                        if (item.id === siblingId) {
                            sort += 2
                            item.sort = sort
                            page.sort = sort - 1
                        } else {
                            sort += 1
                            item.sort = sort
                        }
                    })
            }
        },
        movePage(state, { payload }) {
            const { pageId, siblingId } = payload
            const pageList = getPageList(state)
            const page = state[pageId]
            const siblingIndex = pageList.findIndex((item) => item.id === siblingId)
            if (siblingIndex === 0) {
                let sort = 0
                page.sort = sort
                pageList
                    .filter((item) => item.id !== page.id)
                    .forEach((item, index) => {
                        sort += 1
                        item.sort = sort
                    })
            } else if (siblingId === null || siblingId === undefined) {
                page.sort = pageList.length - 1
                pageList
                    .filter((item) => item.id !== page.id)
                    .forEach((item, index) => {
                        item.sort = index
                    })
            } else {
                let sort = -1
                pageList
                    .filter((item) => item.id !== page.id)
                    .forEach((item, index) => {
                        if (item.id === siblingId) {
                            sort += 2
                            item.sort = sort
                            page.sort = sort - 1
                        } else {
                            sort += 1
                            item.sort = sort
                        }
                    })
            }
        },
        updatePageLoading(state) {
            const pageList = getPageList(state)
            pageList.forEach((page) => {
                if (page.type === 'main') {
                    page.loading = true
                    page.loadingType = 'dynamic'
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPagesThunk.fulfilled, (state, action) => {
            return action.payload
        })
    },
})
