import getTextByType from '@/common/log/type/getTextByType'
import getRequestByType from '@/common/log/type/getRequestByType'

export const customConsole = (label) => {
    if (__LOC__) {
        try {
            console.log(`%c${label}`, 'color: #89cff0')
        } catch (e) {
        }
    }
}

export const pushCustomError = ({
    type, message, info, infoMessage,
}) => {
    new CustomLog({
        message, type, info, infoMessage,
    }).push()
}

export class CustomLog {
    constructor({
        error, type, info, message, infoMessage,
    }) {
        if (typeof error === 'string') {
            this.error = new Error(error)
        } else if (error instanceof Error) {
            this.error = error
        }
        this.message = message
        this.infoMessage = infoMessage

        this.type = type
        this.info = info
        if (__TEST__) {
            this.env = '__TEST__'
        }
        if (__PRO__) {
            this.env = '__PRO__'
        }
    }

    push() {
        let error = {
            message: this.message,
            typeMessage: getTextByType(this.type),
            errMessage: this.error?.message,
            msg: this.error?.msg,
            infoMessage: this.infoMessage,
            code: this.error?.code,
            stack: this.error?.stack,
            type: this.type,
            info: this.info,
            url: window.location.href,
            version: __VERSION__,
            browserInfo: window.browserInfo,
        }
        if (__LOC__) {
            console.log('push error', error, getTextByType(this.type), this.type)
        }

        if (!__LOC__) {
            try {
                const request = getRequestByType(this.type)
                request(JSON.stringify(error))
            } catch (e) {
                console.log(e)
            }
        } else {
        }
    }
}

// 日志类型
