import store from 'store'
import '@/env'
import qs from 'qs'
import { decode } from 'web-iconv'
import 'react-perfect-scrollbar/dist/css/styles.min.css'
import { setLocale } from 'umi'
import { message } from 'antd'
import browserTool from 'browser-tool'
import { isE } from '@/const'
import '@/lib/impress/css/impress-common.css'
import 'react-contexify/dist/ReactContexify.css'
import './styles/tailwind.less'
import { login_anonymous, login_anonymous_new, update_keyword } from '@/service/services'
import { check_webp_feature } from './utils/checkWebpFeature'
import { JzDebug } from './utils/DEV'
import isProxyDomain from './utils/isProxyDomain'
import { log } from './utils/log'

export const dva = {
    config: {
        onError(e) {
            e.preventDefault()
            console.error(e.message)
        },
    },
}

const updateKeyword = async () => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const hasPush = !!localStorage.getItem('hasPushKeyword')
    if (hasPush) return
    let { keyword: keyword$1, source, bd_vid } = query
    if (keyword$1 || source || bd_vid) {
        let keyword
        let logIdUrl = window.location.href
        if (keyword$1) {
            keyword = await decode(keyword$1, 'gb2312')
        }
        return { keyword, source, logIdUrl }
    }
}

export async function render(oldRender) {
    // 判断用户是否装了广告插件
    if (!store.get('user')) {
        let user
        try {
            if (window.location.pathname === '/pptx/down/setLocalStorage') {
                return
            }
            const info = await updateKeyword()
            if (info) {
                user = await login_anonymous_new({
                    referrer: document.referrer,
                    logIdUrl: info.logIdUrl,
                    source: info.source,
                    keyword: info.keyword,
                })
                localStorage.setItem('hasPushKeyword', 'true')
            } else {
                user = await login_anonymous_new({}, { referrer: document.referrer })
            }
            if (user) {
                user.token = user.accessToken
                store.set('user', user)
                store.set('editting_type', 'context')
                store.set('fakeUser', true)
                store.set('isFirst', true)
            }
        } catch (e) {
        }
        if (!user) oldRender()
        user.token = user.accessToken
        store.set('user', user)
        store.set('editting_type', 'context')
        store.set('fakeUser', true)
        store.set('isFirst', true)
        oldRender()
    } else {
        updateKeyword().catch(() => void 0)
        oldRender()
    }
    if (__PRO__) {
        try {
            setTimeout(() => {
                (function (h, o, t, j, a, r) {
                    h.hj = h.hj || function () {
                        (h.hj.q = h.hj.q || []).push(arguments)
                    }
                    h._hjSettings = { hjid: 3426454, hjsv: 6 }
                    a = o.getElementsByTagName('head')[0]
                    r = o.createElement('script')
                    r.async = 1
                    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
                    a.appendChild(r)
                    try {
                        const user = store.get('user') || {}
                        let userId = user?.id || null
                        window.hj?.('identify', userId, {})
                    } catch (e) {

                    }
                }(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='))
                // (function () {
                //   var _53code = document.createElement("script");
                //   _53code.src = "https://tb.53kf.com/code/code/7ab769518c1bbced5a66c46421c7502e5/3";
                //   var s = document.getElementsByTagName("script")[0];
                //   s.parentNode.insertBefore(_53code, s);
                // })();
            }, 2000)
        } catch (e) {

        }
    }
}

if (isE) {
    setLocale('en-US', false)
} else {
    setLocale('zh-CN', false)
}

// 禁用浏览器backspace后退
document.addEventListener('keydown', (event) => {
    let forbiddenTags = ['INPUT', 'TEXTAREA', 'SELECT']
    let targetTag = event.target.tagName.toUpperCase()
    let { isContentEditable } = event.target
    if (event.keyCode === 8 && forbiddenTags.indexOf(targetTag) === -1 && !isContentEditable) {
        // keyCode 8 是 Backspace 键
        event.preventDefault()
    }
})

// 离线判断
const offline = () => {
    if (window.location.pathname === '/pptx/edit') {
        message.error('网络连接异常。请尽快连接网络，内容将在联网后与云端同步。')
    } else {
        message.error('网络连接异常，请检查您的网络连接后重试！')
    }
}

const online = () => {
    message.destroy()
}
window.addEventListener('offline', offline)
window.addEventListener('online', online)

message.config({
    maxCount: 1,
})
// new JSErrorTracker()

check_webp_feature().then((isSupportWebp) => {
    window.isSupportWebp = isSupportWebp
})

const getInfo = async () => {
    const info = await browserTool.getInfo()
    if (info) {
        window.browserInfo = {
            browser: info.browser,
            browserVersion: info.browserVersion,
            platform: info.platform,
            system: info.system,
        }
    }
}
getInfo()

if (__DEV__) {
    window.debug = JzDebug
}
window.log = log
window.guaLog = log
