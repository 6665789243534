import getBaseUrl from '../base_url'
import request from '../request'

export const post = (url, payload, ...args) => {
    return request.post(getBaseUrl() + url, payload, ...args)
}
export const postFile = (url, payload) => {
    return request.postFile(getBaseUrl() + url, payload)
}

export const get = (url, payload) => {
    return request.get(getBaseUrl() + url, payload)
}
