import store from 'store'
import axios from 'axios'
import request from '../utils/request'
import getBaseUrl from '../utils/base_url'
import hashTo from '../web-utils/native/hash-to'
import { strictStringify } from '@/common'
import isProxyDomain from '@/utils/isProxyDomain'
import { importFCUrl_ppt, importFCUrl_word } from '@/const/FCUrl'
import reduxStore from '@/pages/Edit/redux/store'
import { CustomLog } from '@/common/log'
import { GENERATE_PPT_SYLLABUS_TIME_MORE_120, GENERATE_SYLLABUS_TIME_MORE } from '@/common/log/type/logType'
import list, { pageData } from '@/pages/GeneratePPT2/data/pageList'
import { isB, isNewB, isNewUserB } from '@/common/AOrB'
import { GET_CATALOG } from '@/common/log/type/errorType'

export async function getTemplateSimpleList(payload) {
    return request.post(
        `${getBaseUrl()
        }/ppt/template/list?page=${payload.page}&limit=${payload.limit}&themeId=${payload.themeId}`,
        payload,
    )
}

export async function getUserInfo(payload) {
    return request.get(`${getBaseUrl()}/ppt/user/token`, payload)
}

export async function savePpt(payload) {
    return request.post(`${getBaseUrl()}/ppt/ppt/save`, payload)
}

export async function saveFields(payload) {
    return request.post(`${getBaseUrl()}/ppt/ppt/saveFields`, payload)
}

export async function saveStep(payload) {
    return request.post(`${getBaseUrl()}/ppt/ppt/saveStep`, payload)
}

export async function getTypical(payload) {
    return request.get(`${getBaseUrl()}/ppt/ppt/typical/list`, payload)
}

export async function getTypicalGet(payload) {
    return request.get(`${getBaseUrl()}/ppt/ppt/typical/get`, payload)
}

export async function typical(payload) {
    return request.get(`${getBaseUrl()}/ppt/ppt/typical/get`, payload)
}

export async function getPptxList(payload) {
    return request.get(`${getBaseUrl()}/ppt/ppt/list`, payload)
}

export async function deletePPt(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/ppt/delete?id=${payload.pptId}`,
        payload,
    )
}

export async function savePage(payload) {
    return request.post(`${getBaseUrl()}/ppt/page/save`, payload)
}

export async function getPagesByPptxId(payload) {
    return request.get(`${getBaseUrl()}/ppt/page/list`, payload).then((res) => {
        return res
    })
}

export async function getPptxById(payload) {
    return request.get(`${getBaseUrl()}/ppt/ppt/get`, payload)
}

export async function getUserThemeIdByRequestId(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/ai/getUserThemeIdByRequestId${strictStringify(query, true)}`, payload)
}

export async function getPageById(payload) {
    return request.get(`${getBaseUrl()}/ppt/page/get`, payload)
}

export async function getThemeById(payload) {
    return request.get(`${getBaseUrl()}/ppt/theme/get`, payload)
}

export async function pageGetThumbnail(payload) {
    return request.get(`${getBaseUrl()}/ppt/page/get/thumbnail`, payload)
}

export async function getReplaceTemplate(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/page/content/replace?templateId=${payload.templateId}`,
        payload,
    )
}

export async function deletePageById(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/page/delete?id=${payload.id}`,
        payload,
    )
}

export async function getBestTemplate(payload) {
    return request.post(
        `${getBaseUrl()
        }/ppt/page/content/recommend?page=${1}&size=${1}`,
        payload,
    )
}

export async function getTemplateById(payload) {
    return request.get(`${getBaseUrl()}/ppt/template/get`, payload)
}

export async function getTemplateByIdDev(payload) {
    return request.get(`${getBaseUrl()}/ppt/template/getDev`, payload)
}

export async function getRecommendIcon(payload) {
    return request.post(`${getBaseUrl()}/ppt/icons/recommend`, payload)
}

export async function searchIcons(payload) {
    return request.post(`${getBaseUrl()}/ppt/icons/search`, payload)
}

export async function getRecommendPic(payload) {
    return request.post(`${getBaseUrl()}/ppt/picture/recommend`, payload)
}

export async function searchImage(payload) {
    return request.post(`${getBaseUrl()}/ppt/picture/search`, payload)
}

export async function presentationExport(payload) {
    return request.post(`${getBaseUrl()}/ppt/presentation/export`, payload)
}

export async function requestSplitParagraph(payload) {
    return request.post(`${getBaseUrl()}/ppt/ai/splitParagraph`, payload)
}

export async function gzhQRCode(payload) {
    let url
    if (!isProxyDomain()) {
        url = '/ppt/login/wechat/qr'
    } else {
        url = '/ppt/login/wechat/qr2'
    }

    return request.get(getBaseUrl() + url, payload)
}

export async function gzhState(payload) {
    return request.get(`${getBaseUrl()}/ppt/login/wechat`, payload)
}

export async function gzhQRCodeAxios(payload) {
    let url
    if (!isProxyDomain()) {
        url = '/ppt/login/wechat/qr'
    } else {
        url = '/ppt/login/wechat/qr2'
    }
    return axios.get(getBaseUrl() + url + strictStringify(payload))
}

export async function gzhStateAxios(payload) {
    return axios.get(`${getBaseUrl()}/ppt/login/wechat${strictStringify(payload)}`)
}

export async function loginPass(payload) {
    return request.post(`${getBaseUrl()}/ppt/login/password`, {
        body: hashTo(payload),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
}

export async function getUploadList(payload) {
    return request.get(`${getBaseUrl()}/ppt/file/list`, payload)
}

export async function listImageFile(payload) {
    return request.get(`${getBaseUrl()}/ppt/file/listImageFile`, payload)
}

export async function uploadImgAccess(payload) {
    return request.get(`${getBaseUrl()}/ppt/file/post`, payload)
}

export async function create_order(payload) {
    return request.post(`${getBaseUrl()}/ppt/order/create_order`, payload)
}

export async function create_order_edit(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/pptEditedOrder/create_order`,
        payload,
    )
}

export async function create_export_ppt(payload) {
    return request.post(`${getBaseUrl()}/ppt/pptEditedOrder/exportPpt`, payload)
}

export async function pay_weixin(payload = {}) {
    let url = '/ppt/order/payWeiXinParams'

    return request.post(getBaseUrl() + url, {
        ...payload,
        payType: 'NORMAL',
    })
}

export async function pay_pandaOrder_weixin(payload) {
    let url
    if (!isProxyDomain()) {
        url = '/ppt/templateStationOrder/pay_weixin'
    } else {
        url = '/ppt/templateStationOrder/pay_weixin2'
    }
    return request.post(getBaseUrl() + url, payload)
}

export async function pay_alipay(payload) {
    let url
    const user = store.get('user')
    if (!isProxyDomain()) {
        url = '/ppt/order/pay_alipay'
    } else {
        url = '/ppt/order/pay_alipay2'
    }

    return request.post(getBaseUrl() + url, payload)
}

export async function pay_pandaOrder_alipay(payload) {
    let url
    if (!isProxyDomain()) {
        url = '/ppt/templateStationOrder/pay_alipay'
    } else {
        url = '/ppt/templateStationOrder/pay_alipay2'
    }
    return request.post(getBaseUrl() + url, payload)
}

export async function pay_order_query(payload) {
    return request.get(`${getBaseUrl()}/ppt/order/pay_order_query`, payload)
}

export async function pay_pandaOrder_order_query(payload) {
    return request.get(
        `${getBaseUrl()}/ppt/templateStationOrder/pay_order_query`,
        payload,
    )
}

export async function pay_pandaOrder_query(payload) {
    return request.get(
        `${getBaseUrl()}/ppt/templateStationOrder/pay_order_query`,
        payload,
    )
}

export async function get_pay_ppt(payload) {
    return request.get(`${getBaseUrl()}/ppt/order/get_pay_ppt`, payload)
}

export async function get_pandaOrder_pay_ppt(payload) {
    return request.get(
        `${getBaseUrl()}/ppt/templateStationOrder/get_pay_ppt`,
        payload,
    )
}

export async function vip_get_ppt(payload) {
    return request.post(`${getBaseUrl()}/ppt/order/vip_get_ppt`, payload)
}

export async function vip_pandaOrder_get_ppt(payload) {
    return request.post(
        `${getBaseUrl()
        }/ppt/templateStationOrder/vip_get_ppt${
            strictStringify(payload)}`,
    )
}

// 保存前端的缩略图
export async function save_thumbnail(payload) {
    return request.post(`${getBaseUrl()}/ppt/page/save_thumbnail`, payload)
}

// 获取图片缩略图
export async function get_thumbnail(payload) {
    return request.get(`${getBaseUrl()}/ppt/page/get_thumbnail`, payload)
}

// 阿里云oss回调接口
export async function oss_callback(payload) {
    return request.post(`${getBaseUrl()}/ppt/file/callback`, payload)
}

export async function getBestNewTemplate(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/page/content/recommend_new?level=${payload.level}`,
        payload,
    )
}

export async function update_keyword(payload) {
    return request.post(`${getBaseUrl()}/ppt/user/update_keyword`, payload)
}

export async function login_anonymous(payload, query = {}) {
    return request.get(`${getBaseUrl()}/ppt/user/login_anonymous${strictStringify({
        ...query,
    }, true)}`, payload)
}

export async function login_anonymous_new(payload, query = {}) {
    return request.post(`${getBaseUrl()}/ppt/user/login_anonymous_new`, payload)
}

export async function axios_login_anonymous(payload, query) {
    return axios.get(`${getBaseUrl()}/ppt/user/login_anonymous${strictStringify(query, true)}`, payload)
}

export async function imageBatchsave({ pptId, themeId, exampleId }, payload) {
    return request.post(
        `${getBaseUrl()}/image/batchsave/${exampleId}/${pptId}/${themeId}`,
        payload,
    )
}

export function getCaseList(payload) {
    return request.post(`${getBaseUrl()}/ppt/example/page`, payload)
}

export function getCategoryList() {
    return request.get(`${getBaseUrl()}/category/list`)
}

export function getImageList(payload) {
    return request.get(
        `${getBaseUrl()}/image/list/${payload.pptId}/${payload.themeId}`,
    )
}

export function requestSimilarTemplates(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/page/content/similarTemplates`,
        payload,
    )
}

export function requestChangeTemplates(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/page/content/changeTemplates`,
        payload,
    )
}

export function getThemeList(payload) {
    return request.post(`${getBaseUrl()}/ppt/theme/page`, payload)
}

export function templateGetFilter() {
    return request.post(`${getBaseUrl()}/ppt/template/getFilter`)
}

export function getTemplateByFilter({ limit, page, filterIds }) {
    return request.post(
        `${getBaseUrl()
        }/ppt/template/getTemplateByFilter?limit=${limit}&page=${page}`,
        filterIds,
    )
}

export function getFilterCriteria() {
    return request.get(`${getBaseUrl()}/ppt/templateStation/getFilterCriteria`)
}

export function getPandaRecommend(id) {
    return request.get(`${getBaseUrl()}/ppt/templateStation/recommend?id=${id}`)
}

export function getDownloadHistory() {
    return request.get(`${getBaseUrl()}/ppt/templateStation/getDownloadHistory`)
}

export function getDownPandaSearch(data) {
    return request.get(
        `${getBaseUrl()}/ppt/templateStation/search${strictStringify(data)}`,
    )
}

// ?limit=20&page=1&pptId=1085&sortType=0

export function vip_get_pptComposed(data, id) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStationOrder/vip_get_pptComposed?pptId=${id}`,
        data,
    )
}

export function vip_get_pptIntelligentComposed(data, id) {
    return request.post(
        `${getBaseUrl()
        }/ppt/templateStationOrder/vip_get_pptIntelligentComposed?pptId=${id}`,
        data,
    )
}

export function updatePandaTemplate(data) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStationManage/updateTemplate`,
        data,
    )
}

export function pandaToolPostLog({ logType }, data) {
    return request.post(
        `${getBaseUrl()
        }/ppt/templateStationTool/postLog${
            strictStringify({ logType }, true)}`,
        data,
    )
}

export function pandaHalfPrice(data) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStationOrder/halfPrice`,
        data,
    )
}

export function getPayInfo(data) {
    return request.get(`${getBaseUrl()}/ppt/templateStation/getPayInfo`, data)
}

export function getUserAb() {
    return request.post(`${getBaseUrl()}/ppt/templateStation/getUserAb`)
}

export function postDownloadFreeThree(data) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStation/downloadFreeThree`,
        data,
    )
}

export function getPandaList(data) {
    return request.get(
        `${getBaseUrl()}/ppt/templateStation/search${strictStringify(data, true)}`,
    )
}

export function getEditWorkSpaceList(data) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/searchEditable${
            strictStringify(data, true)}`,
    )
}

export function pandaSetTags(data) {
    console.log(strictStringify(data, true))
    return request.post(
        `${getBaseUrl()}/ppt/templateStation/setTags${strictStringify(data, true)}`,
    )
}

export function getAddLikes(data) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/addLikes${
            strictStringify(data, true)}`,
    )
}

export function getPandaFavorite(data) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/favorite${
            strictStringify(data, true)}`,
    )
}

export function getPandaFavorites(data) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/getFavorites${
            strictStringify(data, true)}`,
    )
}

export function getGoodRecommend(data) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/goodRecommend${
            strictStringify(data, true)}`,
    )
}

export async function getSearchIcon(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStation/searchIcon`,
        payload,
    )
}

export async function getSearchPicture(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStation/searchPicture`,
        payload,
    )
}

export async function getSearchSdImage(payload) {
    return request.get(
        `${getBaseUrl()}/animation/searchSdImage`,
        payload,
    )
}

export async function downloadPicture(payload) {
    return request.post(
        `${getBaseUrl()}/ppt/templateStation/downloadPicture`,
        payload,
    )
}

export async function getIntelligentTemplateByFilter(payload, query) {
    return request.post(
        `${getBaseUrl()
        }/ppt/templateStation/getIntelligentTemplateByFilter${
            strictStringify(query, true)}`,
        payload,
    )
}

export function getSimilarPic(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/getSimilarPic${
            strictStringify(query)}`,
    )
}

export async function getHotTemplate(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/getHotTemplate${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function getCheckCanEdit(payload, query) {
    return request.post(
        `${getBaseUrl()
        }/ppt/templateStation/checkCanEdit${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function getEditableId(payload, query) {
    return request.post(
        `${getBaseUrl()
        }/ppt/templateStation/getEditableId${
            strictStringify(query, true)}`,
        payload,
    )
}

export function getPandaGetIds({
    likeName, id, limit, page, sortType,
}) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/list${
            strictStringify({
                id, likeName, limit, page, sortType,
            }, true, false)}`,
    )
}

export async function requestPPTSave(payload, query) {
    return request.post(
        `${getBaseUrl()}/ppt/ppt/v2/save${strictStringify(query, true)}`,
        payload,
    )
}

export async function requestPPTPageSave(payload, query) {
    return request.post(
        `${getBaseUrl()}/ppt/page/v2/save${strictStringify(query, true)}`,
        payload,
    )
}

export async function requestPPTPageSave2(payload, query) {
    return request.post(
        `${getBaseUrl()}/ppt/page/savePage${strictStringify(query, true)}`,
        payload,
    )
}

export function requestUpdateSort({ pptId, list }) {
    return request.post(
        `${getBaseUrl()}/ppt/page/updateSort?pptId=${pptId}`,
        list,
    )
}

export async function deleteByUuid(payload, query) {
    return request.post(
        `${getBaseUrl()}/ppt/page/deleteByUuid${strictStringify(query, true)}`,
        payload,
    )
}

export async function listWithPageData(payload, query) {
    return request.get(
        `${getBaseUrl()}/ppt/ppt/listWithPageData${strictStringify(query, true)}`,
        payload,
    )
}

export async function getMakeInvitationLink(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/coEdit/makeInvitationLink${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function getJoinInvitation(payload, query) {
    return request.get(
        `${getBaseUrl()}/ppt/coEdit/joinInvitation${strictStringify(query, true)}`,
        payload,
    )
}

export async function getPptPreviewData(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/coEdit/getPptPreviewData${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function getEditorsAndViews(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/coEdit/getEditorsAndViews${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function deleteTeamWorker(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/coEdit/deleteTeamWorker${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function getIntelligentTemplateTheme(payload, query, disablePPTId = false) {
    const state = reduxStore.getState()
    let pptId = query.pptId || state?.ppt?.id
    if (disablePPTId) {
        pptId = undefined
    }
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/getIntelligentTemplateTheme${
            strictStringify({ ...query, pptId }, true)}`,
        { ...payload, pptId },
    )
}

//
export async function getIntelligentTemplateTheme2(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/templateStation/getIntelligentTemplateTheme${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function checkIsReader(payload, query) {
    return request.get(
        `${getBaseUrl()}/ppt/coEdit/checkIsReader${strictStringify(query, true)}`,
        payload,
    )
}

export async function nodesColor(payload, query) {
    return request.post(
        `${getBaseUrl()
        }/ppt/ppt/changeTheme/nodesColor${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function modifyTeamworkRole(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/coEdit/modifyTeamworkRole${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function changeAuditState(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/pictureAudit/changeAuditState${
            strictStringify(query, true)}`,
        payload,
    )
}

export async function findDataNotAudit(payload, query) {
    return request.get(
        `${getBaseUrl()
        }/ppt/pictureAudit/findDataNotAudit${
            strictStringify(query, true)}`,
        payload,
    )
}

export const deletePptInWorkSpace = (payload, query) => request.post(
    `${getBaseUrl()
    }/ppt/ppt/deletePptInWorkSpace${
        strictStringify(query, true)}`,
    payload,
)
export const getDataFromRecycle = (payload, query) => request.post(
    `${getBaseUrl()}/ppt/ppt/getDataFromRecycle${strictStringify(query, true)}`,
    payload,
)
export const recoverFromRecycle = (payload, query) => request.post(
    `${getBaseUrl()}/ppt/ppt/recoverFromRecycle${strictStringify(query, true)}`,
    payload,
)
export const deletePermanent = (payload, query) => request.post(
    `${getBaseUrl()}/ppt/ppt/deletePermanent${strictStringify(query, true)}`,
    payload,
)
export const changeMergePptAuditState = (payload, query) => request.get(
    `${getBaseUrl()
    }/ppt/templateStation/changeMergePptAuditState${
        strictStringify(query, true)}`,
    payload,
)
export const getPageBodyByPageId = (payload, query) => request.get(
    `${getBaseUrl()}/ppt/page/getPageBodyByUuid${strictStringify(query, true)}`,
    payload,
)
export const linkIsExpired = (payload, query) => request.get(
    `${getBaseUrl()}/ppt/coEdit/linkIsExpired${strictStringify(query, true)}`,
    payload,
)
export const allBadState = (payload, query) => request.get(
    `${getBaseUrl()
    }/ppt/pictureAudit/allBadState${
        strictStringify(query, true)}`,
    payload,
)

export const getPageBodyByNodes = (payload, query) => request.post(
    `${getBaseUrl()
    }/ppt/page/getPageBodyByNodes${
        strictStringify(query, true)}`,
    payload,
)
export const recommendPageTemplate = (payload, query) => request.post(
    `${getBaseUrl()
    }/ppt/pageTemplate/recommendPageTemplate${
        strictStringify(query, true)}`,
    payload,
)
export const pptMergeAuditisBadPpt = (payload, query) => request.get(
    `${getBaseUrl()}/pptMergeAudit/isBadPpt${strictStringify(query, true)}`,
    payload,
)
export const pptMergeAuditisGoodPpt = (payload, query) => request.get(
    `${getBaseUrl()}/pptMergeAudit/isGoodPpt${strictStringify(query, true)}`,
    payload,
)
export const replaceFontFamily = (payload, query) => request.post(
    `${getBaseUrl()}/ppt/ai/replaceFontFamily${strictStringify(query, true)}`,
    payload,
)

export const recommendPageTemplateByNodeMark = (payload, query) => {
    const state = reduxStore.getState()
    const pptId = state?.ppt?.id
    return request.post(
        `${getBaseUrl()}/ppt/pageTemplate/recommendPageTemplateByNodeMark${strictStringify({ ...query, pptId }, true)}`,
        {
            ...payload,
            pptId,
        },
    )
}

export async function pageModify(payload) {
    return request.post(`${getBaseUrl()}/ppt/page/modify`, payload)
}

export async function aiPPTByKeywordByTemplate(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/aiPPTByKeywordByTemplate${strictStringify(query, true)}`, payload)
}

// themeId
export async function getInitPage(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/pageTemplate/getInitPage${strictStringify(query, true)}`, payload)
}

// templateId
export async function getKeywords(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/ai/getKeywords${strictStringify(query, true)}`, payload)
}

let percentage = 1
let testData = {
    lastValue: 'AI正在飞速撰写PPT内容，最快只需10秒...',
    getPptPercentage: 20,
    percentage,
    getDatePercentage: 50,
    value: 'AI正在飞速撰写PPT内容，最快只需10秒...',
    getTemplatePercentage: 30,
    processingState: {
        analyseText: -1,
        analyseContent: -1,
        generatePage: -1,
        constructPage: -1,
    },
}

let fileObj = {
    userInput: 'asdasdasasdasd 阿斯顿sasdasd 阿斯顿sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'asdasdasasdasd 阿斯顿sasdasd 阿斯顿sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿asdasdasasdasd 阿斯顿sasdasd 阿斯顿sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿asdasdasasdasd 阿斯顿sasdasd 阿斯顿sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n'
        + 'sasdasd 阿斯顿\n',
    pageData,
    fileName: '2023年度信访维稳工作报告.docx',
    size: '82.5KB',
}

export async function getAiPPTByKeywordByTemplatePercentage(payload, query) {
    if (__DEV__) {
        if (window.location.search.indexOf('data=1') !== -1) {
            return Promise.resolve(testData)
        }
    }
    /*    if(__LOC__){
        return  Promise.resolve({
                "getPptPercentage": 100,
                "asyncStatus": "finish",
                "errCode": 10001,
                "percentage": 100,
                "getDatePercentage": 100,
                "value": "生成失败",
                "getTemplatePercentage": 100,
                "errorMsg": "测试异常",
                "status": "finish"
            }
        )
    } */
    return request.get(`${getBaseUrl()}/ppt/ai/getAiPPTByKeywordByTemplatePercentage${strictStringify(query, true)}`, payload)
}

export async function recommendPageTemplateByNodeMarkByPage(payload, query, ...arr) {
    const state = reduxStore.getState()
    const pptId = query.pptId || state?.ppt?.id
    return request.post(
        `${getBaseUrl()}/ppt/pageTemplate/recommendPageTemplateByNodeMarkByPage${strictStringify({
            ...query,
            pptId,
        }, true)}`,
        { ...payload, pptId },
        ...arr,
    )
}

export async function deleteThenSaveBatch(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/page/v2/deleteThenSaveBatch${strictStringify(query, true)}`, payload)
}

export async function updatePageVersion(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/page/updatePageVersion${strictStringify(query, true)}`, payload)
}

export async function auditTheme(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/auditTheme${strictStringify(query, true)}`, payload)
}

export async function auditThemeColor(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/auditThemeColor${strictStringify(query, true)}`, payload)
}

export async function pageRecommend(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/audit/pageRecommend${strictStringify(query, true)}`, payload)
}

export async function auditPageRecommend(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/auditPageRecommend${strictStringify(query, true)}`, payload)
}

export async function getAdaptionTemplate(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/ai/getAdaptionTemplate${strictStringify(query, true)}`, payload)
}

export async function exportPptProcess(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/pptEditedOrder/exportPptProcess${strictStringify(query, true)}`, payload)
}

export async function copyPpt(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/ppt/copyPpt${strictStringify(query, true)}`, payload)
}

export async function importWord(payload, query) {
    return request.post(`${importFCUrl_word}/ppt/ai/importWord${strictStringify(query, true)}`, payload)
}

export async function picRecommendByTemplateId(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/picRecommendByTemplateId${strictStringify(query, true)}`, payload)
}

export async function getMainDescribe(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/nlp/getMainDescribe${strictStringify(query, true)}`, payload)
}

export async function getPointDescribe(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/nlp/getPointDescribe${strictStringify(query, true)}`, payload)
}

export async function getPointTitle(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/nlp/getPointTitle${strictStringify(query, true)}`, payload)
}

export async function transitionPage(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/transitionPage${strictStringify(query, true)}`, payload)
}

export async function CoverPage(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/CoverStruct/CoverPage${strictStringify(query, true)}`, payload)
}

export async function getPptCount(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/getPptCount${strictStringify(query, true)}`, payload)
}

export async function accessTransitionNodeMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/accessTransitionNodeMark${strictStringify(query, true)}`, payload)
}

export async function cancelTransitionNodeMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/cancelTransitionNodeMark${strictStringify(query, true)}`, payload)
}

export async function changeTransitionNodeMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/audit/changeTransitionNodeMark${strictStringify(query, true)}`, payload)
}

export async function changeCoverNodeMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/CoverStruct/changeCoverNodeMark${strictStringify(query, true)}`, payload)
}

export async function accessCoverNodeMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/CoverStruct/accessCoverNodeMark${strictStringify(query, true)}`, payload)
}

export async function cancelCoverNodeMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/CoverStruct/cancelTransitionNodeMark${strictStringify(query, true)}`, payload)
}

export async function changeTemplate(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/changeTemplate${strictStringify(query, true)}`, payload)
}
export async function changeTemplateNew(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/changeTemplateNew${strictStringify(query, true)}`, payload)
}

export async function themeColorData(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/colorAudit/themeColorData${strictStringify(query, true)}`, payload)
}

export async function changeThemeColor(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/colorAudit/changeThemeColor${strictStringify(query, true)}`, payload)
}

export async function getAddPage(payload, query, ...arr) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getAddPage${strictStringify(query, true)}`, payload, ...arr)
}

export async function reMakePage(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/reMakePage${strictStringify(query, true)}`, payload)
}

export async function getNeedsplitData(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/nodeSplit/getNeedSplitData${strictStringify(query, true)}`, payload)
}

export async function getCatalog(payload, query) {
    if (!payload.pptTitle) {
        payload.pptTitle = ''
    }
    new CustomLog({
        message: 'getCatalog接口错误调用',
        type: GET_CATALOG,
        info: { payload, query, url: window.location.href },
    }).push()
    const startDate = new Date()
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getCatalog${strictStringify(query, true)}`, payload).finally(() => {
        const endDate = new Date()
        const value = Date.parse(endDate) - Date.parse(startDate)
        if (value > 120 * 1000) {
            new CustomLog({
                message: `当前生成时间：${value / 1000}秒`,
                type: GENERATE_PPT_SYLLABUS_TIME_MORE_120,
                info: { startDate, endDate },
            }).push()
        } else if (value > (25 * 1000)) {
            new CustomLog({
                message: `当前生成时间：${value / 1000}秒`,
                type: GENERATE_SYLLABUS_TIME_MORE,
                info: { startDate, endDate },
            }).push()
        }
    })
}

export async function getPageListByCatalog(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getPageListByCatalog${strictStringify(query, true)}`, payload)
}

export async function getPageListByCatalogWithoutMain(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getPageListByCatalogWithoutMain${strictStringify(query, true)}`, payload)
}

export async function manyTextFrameMark(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/CoverStruct/manyTextFrameMark${strictStringify(query, true)}`, payload)
}

export async function getUserRemainingDetail(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/order/getUserRemainingDetail${strictStringify(query, true)}`, payload)
}

export async function getNewUserRemainingDetail(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/order/getUserManyVipRemainingDetail${strictStringify(query, true)}`, payload)
}

export async function getUserDownloadHistory(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/getUserDownloadHistory${strictStringify(query, true)}`, payload)
}

export async function getUsedPointHistory(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/order/getUsedPointHistory${strictStringify(query, true)}`, payload)
}

export async function pptAuthorizedDownload(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStationOrder/pptAuthorizedDownload${strictStringify(query, true)}`, payload)
}

export async function setNotifyStateOldUpgrade(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/order/setNotifyStateOldUpgrade${strictStringify(query, true)}`, payload)
}

export async function getUserPayHistory(payload, query) {
    return request.get(`${getBaseUrl()}/ppt/order/getUserPayHistory${strictStringify(query, true)}`, payload)
}

export async function pptAuthorizedDownloadById(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStationOrder/pptAuthorizedDownloadById${strictStringify(query, true)}`, payload)
}

export async function importTheme(payload, query) {
    return request.post(`${importFCUrl_ppt}/ppt/presentation/importTheme${strictStringify(query, true)}`, payload)
}

export async function pageStructural(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/audit/pageStructural${strictStringify(query, true)}`, payload)
}

export async function searchPictureByKeywords(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/searchPictureByKeywords${strictStringify(query, true)}`, payload)
}

export async function splitNodeToPoint(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/splitNodeToPoint${strictStringify(query, true)}`, payload)
}

export async function abbreviate(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/abbreviate${strictStringify(query, true)}`, payload)
}

export async function gpt35getPointDescribe(payload, query) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getPointDescribe${strictStringify(query, true)}`, payload)
}

export const importFileWord = (payload, query, ...arr) => request.post(`${importFCUrl_word}/ppt/file/importFile${strictStringify(query, true)}`, payload, ...arr)
export const importFilePPT = (payload, query) => request.post(`${importFCUrl_ppt}/ppt/file/importFile${strictStringify(query, true)}`, payload)
export const importFileWord2 = (payload, query, ...arr) => {
    return request.post(`${importFCUrl_word}/ppt/file/importFile3${strictStringify(query, true)}`, payload, ...arr)
}

export const getUserImportFileList = (payload, query) => request.post(`${getBaseUrl()}/ppt/file/getUserImportFileList${strictStringify(query, true)}`, payload)
export const deleteImportFile = (payload, query) => request.post(`${getBaseUrl()}/ppt/file/deleteImportFile${strictStringify(query, true)}`, payload)
export const getAsyncResult = (payload, query) => request.get(`${getBaseUrl()}/ppt/ai/getAsyncResult${strictStringify(query, true)}`, payload)
export const storeTempObject = (payload, query) => request.post(`${getBaseUrl()}/ppt/file/storeTempObject${strictStringify(query, true)}`, payload)
export const getTempObject = (payload, query) => request.post(`${getBaseUrl()}/ppt/file/getTempObject${strictStringify(query, true)}`, payload)
export const changeCharts = (payload, query) => request.post(`${getBaseUrl()}/ppt/ai/changeCharts${strictStringify(query, true)}`, payload)
export const refund_weixi = (payload, query) => request.post(`${getBaseUrl()}/ppt/order/refund_weixi${strictStringify(query, true)}`, payload)
export const refund_alipay = (payload, query) => request.post(`${getBaseUrl()}/ppt/order/refund_alipay${strictStringify(query, true)}`, payload)
export const sendCode = (payload, query) => request.get(`${getBaseUrl()}/shortMessage/sendCode${strictStringify(query, true)}`, payload)
export const bindPhonePc = (payload, query) => request.get(`${getBaseUrl()}/ppt/user/bindPhonePc${strictStringify(query, true)}`, payload)
export const changePage = (payload, query, ...arr) => request.post(`${getBaseUrl()}/ppt/ai/gpt35/changePage${strictStringify(query, true)}`, payload, ...arr)

export const exportword = (payload, query) => request.post(`${getBaseUrl()}/ppt/ai/exportWord${strictStringify(query, true)}`, payload)
export const getPptByContent = (payload, query) => request.post(`${getBaseUrl()}/ppt/ai/getPptByContent${strictStringify(query, true)}`, payload)
export const exportWord = (query, payload) => request.post(`${getBaseUrl()}/ppt/ai/exportWord${strictStringify(query, true)}`, payload)
export const getCoverPage = (query, payload) => request.get(`${getBaseUrl()}/ppt/file/getCoverPage${strictStringify(query, true)}`, payload)
export const getCoverPage2 = (query, payload) => request.get(`${getBaseUrl()}/ppt/file/getThemePage${strictStringify(query, true)}`, payload)
export const redoTask = (query, payload) => request.get(`${getBaseUrl()}/ppt/ai/redoTask${strictStringify(query, true)}`, payload)

export const uploadImage = (payload) => {
    return request.postFile(`${getBaseUrl()}/ppt/presentation/upload_image`, payload)
}

export const uploadImageForSelf = (payload) => {
    return request.postFile(`${getBaseUrl()}/ppt/presentation/upLoadImgByPre`, payload)
}

export const uploadImageRevise = (payload) => {
    return request.postFile(`${getBaseUrl()}/ppt/presentation/upload_image_revise`, payload)
}
export const userFeedBack = (payload) => {
    return request.post(`${getBaseUrl()}/ppt/user/userFeedback`, payload)
}
export const shouldFeedBack = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/user/shouldNeedBack`, payload)
}

export const pptChangeColor = (payload) => {
    return request.post(`${getBaseUrl()}/ppt/ppt/changeColor${strictStringify(payload, true)}`, payload)
}
export const getSinglePage = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/page/getSinglePage${strictStringify(payload, true)}`)
}

export const useThemeTemplate = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/templateStation/useThemeTemplate`, payload)
}

export const chooseThemeTemplate = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/templateStation/chooseThemeTemplate`, payload)
}

export const detailClickButton = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/templateStation/clickButton`, payload)
}

// 翻译
export const aiTranslate = (payload) => {
    return request.post(`${getBaseUrl()}/ppt/ai/translate${strictStringify(payload, true)}`, payload)
}
// 按照page换色
export const pageChangeColor = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/templateStation/changeColor${strictStringify(query, true)}`, payload)
}
export const getPptByHtml = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/ai/getPptByHtml${strictStringify(query, true)}`, payload)
}

export const getPptByHtmlAsync = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/ai/getPptByHtmlAsync${strictStringify(query, true)}`, payload)
}

export const getPptDataByHtml = (payload, query) => {
    return request.get(`${getBaseUrl()}/ppt/ai/html_to_ppt_result${strictStringify(query, true)}`, payload)
}

export const downloadPptByOriginId = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/templateStationOrder/download_directly${strictStringify(query, true)}`, payload)
}
// 检查是否可以换色
export const checkCanChangeColor = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/templateStation/checkCanChangeColor`, payload)
}

// 检查是否可以换色
export const userComplaints = (payload) => {
    return request.post(`${getBaseUrl()}/ppt/user/userComplaints`, payload)
}

export const getPptByText = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/ai/createPptByMarkDownText${strictStringify(query, true)}`, payload)
}

export const uploadVideo = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/presentation/uploadVideo${strictStringify(query, true)}`, payload)
}
export const deleteFileBatch = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/presentation/deleteFileBatch${strictStringify(query, true)}`, payload)
}
export const listVideoFile = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/file/listVideoFile`, payload)
}

// export const upload_image = (payload, query) => {
//   return request.post(getBaseUrl() + `/ppt/presentation/upload_image`+strictStringify(query, true), payload)
// }

export const drawContent = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/ai/draw_content${strictStringify(query, true)}`, payload)
}

export const searchContent = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/ai/search_content`, payload)
}

export const makePPTBySearchResults = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/ai/makePPTBySearchResults${strictStringify(query, true)}`, payload)
}

export const searchContentAsync = (payload, ...arr) => {
    return request.get(`${getBaseUrl()}/ppt/ai/search_content_async`, payload, ...arr)
}

export const getChartsColor = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/templateStation/charts_color`, payload)
}

export const deleteCase = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/audit/deleteCase`, payload)
}
export const postWebhook = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/templateStationTool/postWebhook${strictStringify(query, true)}`, payload)
}
export const makePPTWithSearch = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/ai/makePPTWithSearch${strictStringify(query, true)}`, payload)
}
export const postWebhookWarn = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/templateStationTool/postWebhookWarn${strictStringify(query, true)}`, payload)
}
export const postWebhookFrontRunErrMsg = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/templateStationTool/postWebhookFrontRunErrMsg${strictStringify(query, true)}`, payload)
}

export const searchContentwithReason = (payload, query) => {
    return request.get(`${getBaseUrl()}/ppt/audit/searchContentWithReason${strictStringify(query, true)}`, payload)
}
export const searchThemeWithCoverPage = (payload, query) => {
    return request.get(`${getBaseUrl()}/ppt/audit/searchThemeWithCoverPage${strictStringify(query, true)}`, payload)
}

export const getThemeRecommendByWord = (payload, query) => {
    return request.get(`${getBaseUrl()}/ppt/themeRecommend/themeRecommendByWord${strictStringify(query, true)}`, payload)
}

export const improveTextExpression = (payload, query, ...arr) => {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/improveTextExpression${strictStringify(query, true)}`, payload, ...arr)
}

export const deleteTempData = (payload, query, ...arr) => {
    return request.post(`${getBaseUrl()}/ppt/ppt/deleteTempData${strictStringify(query, true)}`, payload, ...arr)
}

export const getPageListByCatalogPre = (payload, query, ...arr) => {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getPageListByCatalogPre${strictStringify(query, true)}`, payload, ...arr)
}

// export const getBlogsList = (query) => {
//   return request.get(getBaseUrl() + '/ppt/blog/listBlog'+strictStringify(query, true))
// }
//
// export const getBlogDetail = (id) => {
//   return request.get(getBaseUrl() + `/ppt/blog/getBlog?blogId=${id}`)
// }

export const getCurUserVipInfo = () => {
    return request.get(`${getBaseUrl()}/ppt/user/getCurUserVipInfo`)
}

export const outlineChange = (payload, query, ...arr) => {
/*    if(__LOC__){
        return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve({
                    "pageCatalogs": [
                        {
                            "pageIndex": 1,
                            "title": "工作总结",
                            "type": "封面页面",
                            "pageType": "cover"
                        },
                        {
                            "pageIndex": 2,
                            "title": "章节1",
                            "type": "过渡页",
                            "pageType": "transition"
                        },
                        {
                            "pageIndex": 3,
                            "title": "工作亮点",
                            "type": "列表页面",
                            "pageType": "main"
                        },
                        {
                            "pageIndex": 4,
                            "title": "团队合作",
                            "type": "列表页面",
                            "pageType": "main"
                        },
                        {
                            "pageIndex": 5,
                            "title": "项目成果",
                            "pageType": ""
                        },
                        {
                            "pageIndex": 6,
                            "title": "章节2",
                            "type": "过渡页",
                            "pageType": "transition"
                        },
                        {
                            "pageIndex": 7,
                            "title": "挑战与解决",
                            "type": "列表页面",
                            "pageType": "main"
                        },
                        {
                            "pageIndex": 8,
                            "title": "个人成长",
                            "pageType": ""
                        },
                        {
                            "pageIndex": 9,
                            "title": "展望未来",
                            "pageType": ""
                        },
                        {
                            "pageIndex": 10,
                            "title": "章节3",
                            "type": "过渡页",
                            "pageType": "transition"
                        },
                        {
                            "pageIndex": 11,
                            "title": "市场分析",
                            "pageType": ""
                        },
                        {
                            "pageIndex": 12,
                            "title": "竞争优势",
                            "type": "列表页面",
                            "pageType": "main"
                        },
                        {
                            "pageIndex": 13,
                            "title": "致谢",
                            "type": "致谢页面",
                            "pageType": "end"
                        }
                    ],
                    "changeType": "CHANGE_PAGE_NUMBER",
                    pptTitle:"s",
                    "pageNumber": 30
                })
            },3000)
        })
    } */
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/outline/change${strictStringify(query, true)}`, payload, ...arr)
}
export const getBlogsList = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/blog/getInternalPptBlogList`, payload)
}
export const getBlogDetail = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/blog/getPptBlogDetail`, payload)
}
export const listCase = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/case/listCase`, payload)
}
export const listImageColor = (payload) => {
    return request.get(`${getBaseUrl()}/image/analyse/themeInit`, payload)
}
export const getImageColor = (payload) => {
    return request.get(`${getBaseUrl()}/image/analyse/image_theme_color_info`, payload)
}

export const animation_type_name_list = (payload) => {
    return request.get(`${getBaseUrl()}/animation/type_name/list`, payload)
}
export const animation_background = (payload, query) => {
    return request.get(`${getBaseUrl()}/animation/background${strictStringify(query, true)}`, payload)
}
export const storeObject = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/file/storeObject${strictStringify(query, true)}`, payload)
}
export const getObject = (payload, query) => {
    return request.post(`${getBaseUrl()}/ppt/file/getObject${strictStringify(query, true)}`, payload)
}
export const listObject = (payload, query) => {
    return request.get(`${getBaseUrl()}/ppt/file/listObject${strictStringify(query, true)}`, payload)
}

export const getImageColorWithType = (payload) => {
    return request.get(`${getBaseUrl()}/image/analyse/image_theme_color_info_with_type`, payload)
}
export const getTempPageTemplateByUuid = (payload) => {
    return request.get(`${getBaseUrl()}/ppt/file/getTempPageTemplateByUuid`, payload)
}

export async function getSinglePageTemplate(payload) {
    return request.get(`${getBaseUrl()}/ppt/pageTemplate/getSinglePageTemplate`, payload)
}

export async function getOssUrl(payload) {
    return request.post(`${getBaseUrl()}/ppt/image/upload`, payload)
}

export async function getPageTemplateUtCase(payload) {
    return request.get(`${getBaseUrl()}/ppt/templateStationTool/getPageTemplateUtCase${strictStringify(payload, true)}`)
}

export const import_process_info = (payload) => {
    if (__DEV__) {
        if (window.location.search.indexOf('data=1') !== -1) {
            return Promise.resolve(fileObj)
        }
    }

    return request.get(`${getBaseUrl()}/template/import/import_process_info${strictStringify(payload, true)}`)
}

export async function themeRecommendGiantScreen(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/themeRecommend/themeRecommendGiantScreen${strictStringify(query, true)}`, payload)
}

export async function getPPTByPageListRowAndTemplateId(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/ai/getPPTByPageListRowAndTemplateId${strictStringify(query, true)}`, payload)
}
export async function getPptByContentAsync(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/ai/getPptByContentAsync${strictStringify(query, true)}`, payload)
}
export async function createPptByMarkDownTextAsync(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/ai/createPptByMarkDownTextAsync${strictStringify(query, true)}`, payload)
}

export async function getOnePageByContent(payload) {
    return request.post(`${getBaseUrl()}/ppt/ai/getOnePageByContent${strictStringify(payload, true)}`, payload)
}

export async function isUserCreateTimeLessThen25(payload) {
    return request.get(`${getBaseUrl()}/ppt/user/isUserCreateTimeLessThen25`, payload)
}

export async function getCasePage(payload) {
    return request.get(`${getBaseUrl()}/ppt/templateStation/getCasePage`, payload)
}
export async function getListCaseTheme(payload) {
    return request.post(`${getBaseUrl()}/ppt/case/listCaseTheme`, payload)
}

export async function hasCasePage(payload) {
    return request.get(`${getBaseUrl()}/ppt/templateStation/hasCasePage`, payload)
}

export async function copyCasePage(payload) {
    return request.get(`${getBaseUrl()}/ppt/templateStation/copyCasePage`, payload)
}
export async function userBehaviorLog(payload) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/userBehaviorLog`, payload)
}
export async function getCatalogAsync(query, payload) {
    new CustomLog({
        message: 'getCatalogAsync接口错误调用',
        type: GET_CATALOG,
        info: { query, payload, url: window.location.href },
    }).push()
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getCatalogAsync${strictStringify(query, true)}`, payload)
}
export async function getCatalogAsyncResult(query, payload) {
    // if(__LOC__){
    //     return  Promise.resolve([
    //             {
    //                 "style": "简约",
    //                 "repoPageList": [
    //                     {
    //                         "pageIndex": "1",
    //                         "title": "工作总结",
    //                         "type": "封面页面"
    //                     },
    //                     {
    //                         "pageIndex": "2",
    //                         "title": "工作亮点",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "项目成果",
    //                         "pageIndex": "3",
    //                         "title": "项目成果",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "4",
    //                         "title": "团队合作",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "个人成长",
    //                         "pageIndex": "5",
    //                         "title": "个人成长",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "6",
    //                         "title": "工作挑战",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "市场竞争",
    //                         "pageIndex": "7",
    //                         "title": "市场竞争",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "技术创新",
    //                         "pageIndex": "8",
    //                         "title": "技术创新",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "9",
    //                         "title": "团队合作",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "10",
    //                         "title": "工作反思",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "pageIndex": "11",
    //                         "title": "问题分析",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "解决方案",
    //                         "pageIndex": "12",
    //                         "title": "解决方案",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "13",
    //                         "title": "经验总结",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "14",
    //                         "title": "工作展望",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "市场趋势",
    //                         "pageIndex": "15",
    //                         "title": "市场趋势",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "技术前景",
    //                         "pageIndex": "16",
    //                         "title": "技术前景",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "个人规划",
    //                         "pageIndex": "17",
    //                         "title": "个人规划",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "18",
    //                         "title": "致谢",
    //                         "type": "致谢页面"
    //                     }
    //                 ],
    //                 "templateId": 292830
    //             },
    //             {
    //                 "style": "简约",
    //                 "repoPageList": [
    //                     {
    //                         "pageIndex": "1",
    //                         "title": "工作总结",
    //                         "type": "封面页面"
    //                     },
    //                     {
    //                         "pageIndex": "2",
    //                         "title": "第一季度工作总结",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "pageIndex": "3",
    //                         "title": "项目进展",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "团队成果",
    //                         "pageIndex": "4",
    //                         "title": "团队成果",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "5",
    //                         "title": "问题与挑战",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "6",
    //                         "title": "第二季度工作总结",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "市场分析",
    //                         "pageIndex": "7",
    //                         "title": "市场分析",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "销售策略",
    //                         "pageIndex": "8",
    //                         "title": "销售策略",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "9",
    //                         "title": "客户反馈",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "10",
    //                         "title": "第三季度工作总结",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "产品改进",
    //                         "pageIndex": "11",
    //                         "title": "产品改进",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "市场竞争",
    //                         "pageIndex": "12",
    //                         "title": "市场竞争",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "13",
    //                         "title": "销售数据",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "14",
    //                         "title": "第四季度工作总结",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "团队成长",
    //                         "pageIndex": "15",
    //                         "title": "团队成长",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "市场趋势",
    //                         "pageIndex": "16",
    //                         "title": "市场趋势",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "17",
    //                         "title": "未来展望",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "18",
    //                         "title": "致谢",
    //                         "type": "致谢页面"
    //                     }
    //                 ],
    //                 "templateId": 292830
    //             },
    //             {
    //                 "style": "简约",
    //                 "repoPageList": [
    //                     {
    //                         "pageIndex": "1",
    //                         "title": "工作总结",
    //                         "type": "封面页面"
    //                     },
    //                     {
    //                         "pageIndex": "2",
    //                         "title": "引言",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "项目成果",
    //                         "pageIndex": "3",
    //                         "title": "项目成果",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "4",
    //                         "title": "团队合作",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "5",
    //                         "title": "个人贡献",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "6",
    //                         "title": "项目分析",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "市场调研",
    //                         "pageIndex": "7",
    //                         "title": "市场调研",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图表页面",
    //                         "rowTitle": "竞争对手",
    //                         "pageIndex": "8",
    //                         "title": "竞争对手",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "SWOT分析",
    //                         "pageIndex": "9",
    //                         "title": "SWOT分析",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "pageIndex": "10",
    //                         "title": "总结与展望",
    //                         "type": "过渡页"
    //                     },
    //                     {
    //                         "pageIndex": "11",
    //                         "title": "项目总结",
    //                         "type": "列表页面"
    //                     },
    //                     {
    //                         "rowType": "图文页面",
    //                         "rowTitle": "未来规划",
    //                         "pageIndex": "12",
    //                         "title": "未来规划",
    //                         "type": "列表页面"
    //                     }
    //                 ],
    //                 "templateId": 292830
    //             }
    //         ])

    // }
    return request.get(`${getBaseUrl()}/ppt/ai/gpt35/getCatalogAsyncResult${strictStringify(query, true)}`, payload)
}
export async function getPageListByCatalogAsync(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/ai/gpt35/getPageListByCatalogAsync${strictStringify(query, true)}`, payload)
}
export async function getPageListByCatalogAsyncResult(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/ai/gpt35/getPageListByCatalogAsyncResult${strictStringify(query, true)}`, payload)
}

export async function getWordImportPage(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/page/getWordImportPage${strictStringify(query, true)}`, payload)
}

export async function getPlatePageHaveKeyValue(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/page/getPlatePageHaveKeyValue${strictStringify(query, true)}`, payload)
}

export async function getKeywordByData(payload) {
    return request.get(`${getBaseUrl()}/ppt/ai/getKeywordByData`, payload)
}

export async function user_word_generation(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_word_generation${strictStringify(query, true)}`, payload)
}
export async function user_word_content_regenerate(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_word_content_regenerate${strictStringify(query, true)}`, payload)
}
export async function getUserPptByKeywords(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/ai/getUserPptByKeywords${strictStringify(query, true)}`, payload)
}
export async function user_keyword_generation(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_keyword_regenerate`, payload)
}
export async function checkValueExist(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/checkValueExist${strictStringify(query, true)}`, payload)
}
export async function user_content_generation(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_content_generation${strictStringify(query, true)}`, payload)
}
export async function user_content_regeneration(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_content_regeneration${strictStringify(query, true)}`, payload)
}
export async function user_html_generation(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_html_generation${strictStringify(query, true)}`, payload)
}
export async function user_word_regenerate(query, payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_word_regenerate${strictStringify(query, true)}`, payload)
}

export async function downloadTemplate(payload) {
    return request.post(`${getBaseUrl()}/ppt/templateStationOrder/download_template`, payload)
}
export async function saveCasePageTemplate(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/case/saveCasePageTemplate${strictStringify(query, true)}`, payload)
}
export async function savePageTemplateFormat(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/pageTemplate/savePageTemplateFormat${strictStringify(query, true)}`, payload)
}

export async function getCoverPageTemplate(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/case/getCoverPageTemplate${strictStringify(query, true)}`, payload)
}
export async function changeCoverPageTemplate(query, payload) {
    return request.post(`${getBaseUrl()}/ppt/case/changeCoverPageTemplate${strictStringify(query, true)}`, payload)
}
export async function listCaseType(payload) {
    return request.get(`${getBaseUrl()}/ppt/case/listCaseType`, payload)
}

export async function saveCaseStyle(payload) {
    return request.post(`${getBaseUrl()}/ppt/case/case/name_style/change`, payload)
}

export async function getListCaseTemplate(payload) {
    return request.get(`${getBaseUrl()}/ppt/case/listCaseTemplate`, payload)
}

export async function getWordByRecordId(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/get_word_by_record_id`, payload)
}

export async function getListCaseFeed(payload) {
    return request.get(`${getBaseUrl()}/ppt/case/listCaseFeed`, payload)
}

export async function getPicWithSearchInfo(payload) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/searchPictureWithSearchInfo`, payload)
}

export async function getPicWithSearchInfo2(payload) {
    return request.post(`${getBaseUrl()}/ppt/templateStation/searchPictureWithSearchInfo`, payload)
}

export async function getIconWithSearchInfo(payload) {
    return request.post(`${getBaseUrl()}/ppt/developer/iconEsSearchTest`, payload)
}

export async function modifyMakeCaseInfo(payload) {
    return request.post(`${getBaseUrl()}/ppt/case/modifyMakeCaseInfo`, payload)
}

export async function modifyCaseType(payload) {
    return request.get(`${getBaseUrl()}/ppt/case/makeCaseInfoTempBodySignMainNeedAddBlankBoundary`, payload)
}

export async function modifyImagePosition(payload) {
    return request.post(`${getBaseUrl()}/ppt/case/case_theme_tech_background_image_title_position_locate`, payload)
}

export async function getCheckInputPptDataByMd(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_md_generation`, payload)
}

export async function getCheckInputPptDataByFm(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_freemind_generation`, payload)
}

export async function getCheckInputPptDataByHt(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/user_url_generation`, payload)
}

export async function modifyMakeCaseInfoAvailableState(payload) {
    return request.post(`${getBaseUrl()}/ppt/case/modifyMakeCaseInfoAvailableState`, payload)
}

export async function getPPTWithKeyWordByRecordId(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/get_keywords_by_record_id`, payload)
}

export async function getPPTWithSdByRecordId(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/get_content_by_record_id`, payload)
}

export async function getPPTWithAutoByRecordId(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/get_html_by_record_id`, payload)
}

export async function reGenPptDataByMd(payload) {
    return request.post(`${getBaseUrl()}/ppt/developer/user_md_content_regenerate`, payload)
}

export async function getMgImgList(payload) {
    return request.get(`${getBaseUrl()}/animation/listSdImage`, payload)
}

export async function updateMgImg(payload) {
    const { id } = payload
    return request.post(`${getBaseUrl()}/animation/updateImage?id=${id}`, payload.sdImage)
}

export async function findBackgroundImageUrl(payload) {
    return request.get(`${getBaseUrl()}/ppt/case/findBackgroundImageUrl`, payload)
}

export async function getWordValidateByTag(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/get_word_validate_by_tag`, payload)
}

export async function getWordValidateByRecord(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/get_word_validate_by_record_id`, payload)
}

export async function wordToPptRegen(payload) {
    return request.get(`${getBaseUrl()}/ppt/developer/word_to_ppt_regen`, payload)
}

export async function getCustomThemeColorList(payload) {
    return request.get(`${getBaseUrl()}/ppt/ppt/ppt_custom_color/list`, payload)
}

export async function addCustomThemeColor(payload) {
    return request.post(`${getBaseUrl()}/ppt/ppt/ppt_custom_color/add`, payload)
}

export async function deleteCustomThemeColor(payload) {
    return request.post(`${getBaseUrl()}/ppt/ppt/ppt_custom_color/delete?id=${payload.id}`)
}

export async function updateCustomThemeColor(payload) {
    return request.post(`${getBaseUrl()}/ppt/ppt/ppt_custom_color/update?`, payload)
}
