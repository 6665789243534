import { cloneDeep } from 'lodash'
import { getFirstFontSize } from '@/pages/Edit/common/node/utils'
import { EAlignText } from '@/pages/Edit/type/ITextNode'

// 处理特殊字体的误差
const mapFontToDeviation = {
    云峰飞云体: 168 / 180,
    'hongleisim-Regular': 172 / 180,
    gongfannufangti: 172 / 180,
    演示春风楷: 168 / 196,
    DOUYUFont: 168 / 218,
}

const getFontFamilyByHTML = (htmlStr) => {
    const div = document.createElement('div')
    div.innerHTML = htmlStr
    const fontFamily = div.querySelector('p')?.style?.fontFamily || div.querySelector('span')?.style?.fontFamily || ''
    return fontFamily
}

const getHTMLTextAlign = (htmlStr) => {
    // 用正则获取第一个text-align属性并返回
    const match = htmlStr.match(/text-align:\s*([^;]+);?/)
    return match ? match[1] : null
}

// 存在一个问题，如果加载一些自定义字体的时候，浏览器需要等待字体加载完成，此时计算的话，浏览器会用默认字体
// 进行渲染计算，会导致计算结果不准确
// 初版算法，无法突破浏览器12px限制
export const calcFontSize = (node) => {
    let htmlStr = node.value
    const containerHeight = node.style.height
    let fontSize = getFirstFontSize(htmlStr) || node.style.fontSize || 14

    // 清空fontSize，方便后续计算
    htmlStr = htmlStr.replace(/\s*font-size:\s*[^;]+;?/gi, '')
    const div = document.createElement('div')
    div.innerHTML = htmlStr
    div.style.width = `${node.style.width}px`
    div.style.position = 'absolute'
    div.style.lineHeight = node.style.lineHeight
    div.style.letterSpacing = node.style.letterSpacing
    div.style.fontSize = `${fontSize}px`
    div.style.wordBreak = 'break-all'
    div.style.webkitTextSizeAdjust = 'none'
    document.body.append(div)
    let height = div.getBoundingClientRect().height
    let flag = false
    while (height > containerHeight && fontSize > 8) {
        fontSize -= 1
        div.style.fontSize = `${fontSize}px`
        height = div.getBoundingClientRect().height
        flag = true
    }
    document.body.removeChild(div)
    if (flag) {
        return {
            fontSize,
            height,
        }
    }
    return {
        height,
    }
}

const getText = (htmlStr) => {
    // 直接用textContent获取
    const div = document.createElement('div')
    div.innerHTML = htmlStr
    return div.textContent
}

// 1020922170
export const adjustZoomTextFontSizeByPage = (page) => {
    if (!page?.nodes) return

    try {
        const nodes = page.nodes
        Object.keys(nodes).forEach((key) => {
            const node = nodes[key]
            if (!node) return
            const isText = node?.type === 'text'
            const isZoom = node?.textAdjustment === 'zoom'
            const isWrap = node?.wordWrap === true
            const isVertical = node?.textDirection?.includes('vertical')
            // 将所有横排缩排的文本转成自动高度文本
            if (isText && isZoom && isWrap && !isVertical) {
                let { fontSize, height } = calcFontSize(node)
                // 特殊处理目录页序号
                if (page.type === 'catalog') {
                    const text = getText(node.value)
                    if (Number(text)) {
                        node.style.width += 10
                        node.style.left -= 2
                    }
                }
                // 更新字体大小
                if (fontSize) {
                    // 只处理ppt相关的，特殊字体做误差处理
                    const pathname = window.location.pathname
                    if (pathname.includes('generate') && !pathname.includes('generate-design')) {
                        const fontFamily = getFontFamilyByHTML(node.value)
                        const deviation = mapFontToDeviation[fontFamily] || 1
                        fontSize *= deviation
                    }
                    node.style.fontSize = fontSize
                    node.value = node.value.replace(/\s*font-size:\s*[^;]+;?/gi, '')
                }
                // 更新文本高度
                if (height) {
                    // 如果文本高度大于容器高度，则需要调整node的位置
                    if (node.style.height && node.style.height > height) {
                        const align = getHTMLTextAlign(node.value)
                        if (node.alignText === EAlignText.center || align === 'center') {
                            node.style.top += (node.style.height - height) / 2
                        }
                        if (node.alignText === EAlignText.end || align === 'end') {
                            node.style.top += node.style.height - height
                        }
                    }
                    node.style.height = height
                }
                node.style.zoom = 1
                node.wordWrap = true
                node.textAdjustment = 'auto-rect'
            }

            if (isText && isZoom && isWrap && isVertical) {
                let { fontSize, width } = calcVerticalFontSize(node)
                if (fontSize) {
                    node.style.fontSize = fontSize
                    node.value = node.value.replace(/\s*font-size:\s*[^;]+;?/gi, '')
                }
                node.style.zoom = 1
                node.wordWrap = true
                node.textAdjustment = 'none'
            }
        })
    } catch (error) {
        console.error(error)
        return page
    }
}

// 缩排的文本转成自动高度文本，数据来源传参应该是pages
export const adjustZoomTextFontSize = (pages) => {
    const p = cloneDeep(pages)
    Object.keys(p).forEach((key) => {
        const page = p[key]
        if (page.type !== 'html') {
            adjustZoomTextFontSizeByPage(page)
        }
    })

    return p
}

export const calcVerticalFontSize = (node) => {
    let htmlStr = node.value
    const containerWidth = node.style.width
    let fontSize = getFirstFontSize(htmlStr) || node.style.fontSize || 14

    // 清空fontSize，方便后续计算
    htmlStr = htmlStr.replace(/\s*font-size:\s*[^;]+;?/gi, '')
    const div = document.createElement('div')
    div.innerHTML = htmlStr
    div.style.height = `${node.style.height}px`
    div.style.position = 'absolute'
    div.style.lineHeight = node.style.lineHeight
    div.style.letterSpacing = node.style.letterSpacing
    div.style.fontSize = `${fontSize}px`
    div.style.writingMode = 'vertical-rl' // 设置竖排模式
    div.style.wordBreak = 'break-all'
    div.style.webkitTextSizeAdjust = 'none'
    document.body.append(div)
    let width = div.getBoundingClientRect().width
    let flag = false

    while (width > containerWidth && fontSize > 8) {
        fontSize -= 1
        div.style.fontSize = `${fontSize}px`
        width = div.getBoundingClientRect().width
        flag = true
    }

    document.body.removeChild(div)
    if (flag) {
        return {
            fontSize,
            width,
        }
    }
    return {
        width,
    }
}
